let salaires = {
    "1200": 10, // 10% gagnent moins de 1200 euros net/mois
    "1350": 20, // 20% gagnent moins de 1350 euros net/mois
    "1500": 30, // 30% gagnent moins de 1500 euros net/mois
    "1620": 40, // 40% gagnent moins de 1620 euros net/mois
    "1800": 50, // 50% gagnent moins de 1800 euros net/mois (médian)
    "2000": 60, // 60% gagnent moins de 2000 euros net/mois
    "2280": 70, // 70% gagnent moins de 2280 euros net/mois
    "2700": 80, // 80% gagnent moins de 2700 euros net/mois
    "3600": 90, // 90% gagnent moins de 3600 euros net/mois
    "4700": 95,  // 95% gagnent moins de 4700 euros net/mois
    "8600": 99   // 99% gagnent moins de 8600 euros net/mois
  };
  
export default salaires;