let cringe = [
    "J'espère que t'es encore à  l'école fréro 🤓",
    "Même pas le smic 🤤",
    "Bientôt les vacances à Roubaix 🤩"
]

let bas = [
    "Bien guez 🔥", 
    "Lâche pas l'école 🤣",
    "Force mon reuf 😬🙏🏼"

]

let moyen = [
    "Ouais, pas mal 🤭",
    "Ca commence à brasser 🤞",
    "Plus que la plupart des darons 😎"
]

let haut = [
    "Ca vie heinnnn 🤑",
    "Mens doucement on sait que tu taff au Mcdo 🤥",
    "Tu peux être sugar daddy c'est bon 😏"
]

function getCommentaire(pourcentage){
    if(pourcentage === null)
        return null
    else if (pourcentage === 1){
        const indexAleatoire = Math.floor(Math.random() * cringe.length);
        return cringe[indexAleatoire]
    }
    else if(pourcentage < 50 ){
        const indexAleatoire = Math.floor(Math.random() * bas.length);
        return bas[indexAleatoire]
    }
    else if( pourcentage < 75){
        const indexAleatoire = Math.floor(Math.random() * moyen.length);
        return moyen[indexAleatoire]
    }
    else{
        const indexAleatoire = Math.floor(Math.random() * haut.length);
        return haut[indexAleatoire]
    }
}

export default getCommentaire