import salaires from "../datas/france";
import { useState } from "react";
import "./Home.css"
import getCommentaire from "../expressions/expressions";

function Home(){
    const [salary, setSalary] = useState('');
    const [percentage, setPercentage] = useState(null);
    const [commentaire, setCommentaire] = useState(null);

    const handleInputChange = (e) => {
        setSalary(e.target.value);
        const salaryInput = parseInt(e.target.value);

        // Chercher dans le dictionnaire le pourcentage associé
        let foundPercentage = null;
        for (let key in salaires) {
            if (parseInt(key) <= salaryInput) {
                foundPercentage = salaires[key];
            } else {
                break;
            }
        }
        console.log(salaryInput)
        console.log(foundPercentage)
        if(foundPercentage === null && !isNaN(salaryInput)){
            foundPercentage = 1
            console.log("OUI")
        }
            

        setPercentage(foundPercentage);
        setCommentaire(getCommentaire(foundPercentage));
    };

    return(
        <div className="Home">
            <div className="titre-container">
                <span className="titre">T'es riche ou pas ?</span>
            </div>
            <div className="input-container">
                <input 
                    type="number" 
                    placeholder="Ton salaire net mensuel" 
                    value={salary} 
                    onChange={handleInputChange} 
                    className="input-salaire"
                />
                {commentaire !== null && (
                    <p className="text-salaire">{commentaire}</p>
                )}
                {percentage !== null && (
                    <p className="text-salaire">Tu gagnes plus que {percentage}% de la population !</p>
                )}
            </div>
        </div>
    )
}

export default Home;