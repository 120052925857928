// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-salaire{
    min-width: 50%;
    height: 3em;
    border: none;
    border-radius: 4px;
    font-size: large;
    padding-inline: 10px;
}

.titre{
    justify-items: center;
    font-size: xxx-large;
    font-weight: bold;
    background-color: #137C8B;
    color: #B8CBD0;
    animation: skewAnimation 2.5s infinite alternate;
    padding: 15px;
}

@keyframes skewAnimation {
    0%, 20% {
        transform: skew(-15deg);
    }
    26%, 70% {
        transform: skew(15deg);
    }
    75%, 100% {
        transform: skew(-15deg);
    }
}

.titre-container{
    display: flex;
    justify-content: center;
    padding: 4em;
}

.text-salaire{
    color: whitesmoke;
    font-weight: 700;
    font-size: xx-large;
    text-align: center;
}

.input-container{
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Home{
    min-height: 100vh;
    min-width: 100vw;
    
}`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,gDAAgD;IAChD,aAAa;AACjB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;;AAEpB","sourcesContent":[".input-salaire{\r\n    min-width: 50%;\r\n    height: 3em;\r\n    border: none;\r\n    border-radius: 4px;\r\n    font-size: large;\r\n    padding-inline: 10px;\r\n}\r\n\r\n.titre{\r\n    justify-items: center;\r\n    font-size: xxx-large;\r\n    font-weight: bold;\r\n    background-color: #137C8B;\r\n    color: #B8CBD0;\r\n    animation: skewAnimation 2.5s infinite alternate;\r\n    padding: 15px;\r\n}\r\n\r\n@keyframes skewAnimation {\r\n    0%, 20% {\r\n        transform: skew(-15deg);\r\n    }\r\n    26%, 70% {\r\n        transform: skew(15deg);\r\n    }\r\n    75%, 100% {\r\n        transform: skew(-15deg);\r\n    }\r\n}\r\n\r\n.titre-container{\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 4em;\r\n}\r\n\r\n.text-salaire{\r\n    color: whitesmoke;\r\n    font-weight: 700;\r\n    font-size: xx-large;\r\n    text-align: center;\r\n}\r\n\r\n.input-container{\r\n    padding: 20px;\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\n.Home{\r\n    min-height: 100vh;\r\n    min-width: 100vw;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
